import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Sezione from "../components/sezione"
import SEO from "../components/seo"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import CardPagina from "../components/cardPagina"
import { Container, Row, Col } from "react-bootstrap"
import {Helmet} from "react-helmet"

const FormOK = () => {
  return (
    <Container>
      <Helmet>
        <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', 'AW-1046403060');
              gtag('event', 'conversion', {'send_to': 'AW-1046403060/-pC_CNTdvggQ9K_78gM'});
            `}
          </script>
        <meta http-equiv="refresh" content="4;url=.. " />
      </Helmet>
      <SEO
        title="Invio richiesta avvenuto con successo!"
        description="Hai inviato con successo il tuo preventivo....verrai reindirizzato a breve!"
      />
      <CardPagina variante="bordoTop bordoBottom">
        <Row className="text-center">
          <img
            className="rounded mt-1 mx-auto img-fluid "
            src="../nonsoloenergiatitle.png"
            alt="NonsoloEnergia | Fotovoltaico Lazio"
          />
        </Row>
        <h2 className="gridParity mt-5 text-center">
          Grazie per averci contattato!
        </h2>
        <br />
        <p className="text-center">
          I nostri ingegneri si occuperanno di analizzare la tua richiesta e
          di offrirti il miglior servizio.
        </p>
        <p className="text-center">Verrai ricontattato appena possibile</p>
        <br></br>
        <b>
          <p className="text-center">
            Verrai rendirizzato alla homepage tra pochi secondi...
          </p>
        </b>
      </CardPagina>
    </Container>
  )
}

export default FormOK
